import { defaultQualification } from '@/constants/qualification';
import { defaultResidency } from '@/constants/residency';

const { ACTIVE_YEAR } = process.env;
const DISABLE_SAMPLE_PLANS = process.env.DISABLE_SAMPLE_PLANS === 'true';
const DEFAULT_QUALIFICATION = defaultQualification();
const DEFAULT_RESIDENCY = defaultResidency();

export default () => ({
  breadcrumbs: [],
  profile: {
    open: false,
    loggedin: false,
    qualification: DEFAULT_QUALIFICATION,
    qualificationCode: 'AUS-VCE',
    residency: DEFAULT_RESIDENCY,
    persona: 'secondary',
    method: 'default',
    year: parseInt(ACTIVE_YEAR, 10),
  },
  featureFlags: {
    disableSamplePlans: DISABLE_SAMPLE_PLANS,
    entryreqs: true,
    activeYear: parseInt(ACTIVE_YEAR, 10),
  },
});
