import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _905cc1c6 = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _66e87c20 = () => interopDefault(import('../pages/find/pathways/index.vue' /* webpackChunkName: "pages/find/pathways/index" */))
const _5d8ee73e = () => interopDefault(import('../pages/find/study-areas/index.vue' /* webpackChunkName: "pages/find/study-areas/index" */))
const _775d74ff = () => interopDefault(import('../pages/find/courses/honours-specialisation/index.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/index" */))
const _75a6d3b7 = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug" */))
const _6f45160c = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug/index.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug/index" */))
const _a2a30dca = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug/structure.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug/structure" */))
const _35f2838a = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug/_custom/index.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug/_custom/index" */))
const _0b9a74c7 = () => interopDefault(import('../pages/find/microcredentials/_slug.vue' /* webpackChunkName: "pages/find/microcredentials/_slug" */))
const _6531dad8 = () => interopDefault(import('../pages/find/pathways/_slug.vue' /* webpackChunkName: "pages/find/pathways/_slug" */))
const _7e16414a = () => interopDefault(import('../pages/find/pathways/_slug/index.vue' /* webpackChunkName: "pages/find/pathways/_slug/index" */))
const _24bab6be = () => interopDefault(import('../pages/find/short-courses/_slug.vue' /* webpackChunkName: "pages/find/short-courses/_slug" */))
const _b37ae268 = () => interopDefault(import('../pages/find/study-areas/_id/index.vue' /* webpackChunkName: "pages/find/study-areas/_id/index" */))
const _c02643f8 = () => interopDefault(import('../pages/find/courses/_component/_slug.vue' /* webpackChunkName: "pages/find/courses/_component/_slug" */))
const _e4985cf2 = () => interopDefault(import('../pages/find/courses/_component/_slug/index.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/index" */))
const _481c3376 = () => interopDefault(import('../pages/find/courses/_component/_slug/career-outcomes.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/career-outcomes" */))
const _20e261b4 = () => interopDefault(import('../pages/find/courses/_component/_slug/entry-requirements.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/entry-requirements" */))
const _1c7a7308 = () => interopDefault(import('../pages/find/courses/_component/_slug/fees.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/fees" */))
const _aa528a58 = () => interopDefault(import('../pages/find/courses/_component/_slug/how-to-apply.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/how-to-apply" */))
const _1d8c71b0 = () => interopDefault(import('../pages/find/courses/_component/_slug/structure.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/structure" */))
const _4de8dcd7 = () => interopDefault(import('../pages/find/courses/_component/_slug/student-experience.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/student-experience" */))
const _888f37e0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _93c35a72 = () => interopDefault(import('../modules/study-index/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/styleguide",
    component: _905cc1c6,
    name: "styleguide"
  }, {
    path: "/find/pathways",
    component: _66e87c20,
    name: "find-pathways"
  }, {
    path: "/find/study-areas",
    component: _5d8ee73e,
    name: "find-study-areas"
  }, {
    path: "/find/courses/honours-specialisation",
    component: _775d74ff,
    name: "find-courses-honours-specialisation"
  }, {
    path: "/find/courses/honours-specialisation/:slug?",
    component: _75a6d3b7,
    children: [{
      path: "",
      component: _6f45160c,
      name: "find-courses-honours-specialisation-slug"
    }, {
      path: "structure",
      component: _a2a30dca,
      name: "find-courses-honours-specialisation-slug-structure"
    }, {
      path: ":custom",
      component: _35f2838a,
      name: "find-courses-honours-specialisation-slug-custom"
    }]
  }, {
    path: "/find/microcredentials/:slug?",
    component: _0b9a74c7,
    name: "find-microcredentials-slug"
  }, {
    path: "/find/pathways/:slug",
    component: _6531dad8,
    children: [{
      path: "",
      component: _7e16414a,
      name: "find-pathways-slug"
    }]
  }, {
    path: "/find/short-courses/:slug?",
    component: _24bab6be,
    name: "find-short-courses-slug"
  }, {
    path: "/find/study-areas/:id",
    component: _b37ae268,
    name: "find-study-areas-id"
  }, {
    path: "/find/courses/:component?/:slug?",
    component: _c02643f8,
    children: [{
      path: "",
      component: _e4985cf2,
      name: "find-courses-component-slug"
    }, {
      path: "career-outcomes",
      component: _481c3376,
      name: "find-courses-component-slug-career-outcomes"
    }, {
      path: "entry-requirements",
      component: _20e261b4,
      name: "find-courses-component-slug-entry-requirements"
    }, {
      path: "fees",
      component: _1c7a7308,
      name: "find-courses-component-slug-fees"
    }, {
      path: "how-to-apply",
      component: _aa528a58,
      name: "find-courses-component-slug-how-to-apply"
    }, {
      path: "structure",
      component: _1d8c71b0,
      name: "find-courses-component-slug-structure"
    }, {
      path: "student-experience",
      component: _4de8dcd7,
      name: "find-courses-component-slug-student-experience"
    }]
  }, {
    path: "/",
    component: _888f37e0,
    name: "index"
  }, {
    path: "/find",
    component: _93c35a72,
    name: "study-index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
