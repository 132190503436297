import Api from '@/helpers/api';
import { STATUS_200, STATUS_300 } from '@/constants/api';

export default {
  /*
   * Fetches offering record and commits to store
   */
  async setOfferingData({ commit }, slug) {
    const { data, status } = await Api.getOffering(slug.toLowerCase());
    if (status >= STATUS_200 && status < STATUS_300 && data) {
      await commit('SET_OFFERING', { data });
    }
  },
};
