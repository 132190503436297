import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import pickby from 'lodash.pickby';
import { xssFilter } from '../helpers/xssFilter';

import { qualifications } from '../constants/qualification';
import { residencies } from '../constants/residency';
import { personas } from '../constants/personas';

const getCustomQuery = (param, query) => {
  if (query[param]) {
    const filterQuery = query[param];

    return xssFilter(filterQuery);
  }

  return '';
};

const checkValidQuery = (query, validArray) => {
  if (validArray.some((el) => el.id === query)) {
    return query;
  }

  return '';
};

const getQualification = (query) => {
  const queryQualification = getCustomQuery('qualification', query);
  const validQuery = checkValidQuery(queryQualification, qualifications);

  return validQuery;
};

const getResidency = (query) => {
  const queryResidency = getCustomQuery('residency', query);
  const validQuery = checkValidQuery(queryResidency, residencies);

  return validQuery;
};

const getPersona = (query) => {
  const queryPersona = getCustomQuery('persona', query);
  const validQuery = checkValidQuery(queryPersona, personas);

  return validQuery;
};

const getParams = (query) => {
  const qualification = getQualification(query);
  const residency = getResidency(query);
  const persona = getPersona(query);

  const params = {
    qualification,
    residency,
    persona,
  };

  // Filter out empty params.
  const profile = pickby(params, (param) => param !== '');

  return profile;
};

const cookiesEmptyOrMatchDefault = (defaultProfile, cookieProfile) => {
  if (!cookieProfile) {
    return true;
  }

  return Object.keys(defaultProfile).every(
    (key) => defaultProfile[key] === cookieProfile.profile[key],
  );
};

const locationPreviouslyRequested = (cookieProfile) =>
  cookieProfile && cookieProfile.profile.locationResponse === 'yes';

const isWithinAustralia = (latitude, longitude) => {
  // Define the latitude and longitude boundaries of Australia
  const australiaBounds = {
    north: -10,
    south: -43.7,
    west: 113,
    east: 153.6,
  };

  if (
    latitude >= australiaBounds.south &&
    latitude <= australiaBounds.north &&
    longitude >= australiaBounds.west &&
    longitude <= australiaBounds.east
  ) {
    return true;
  }
  return false;
};

const mergedProfile = (profile, defaultProfile, cookieProfile) => {
  let combinedProfile;

  if (profile && Object.keys(profile).length) {
    combinedProfile = {
      ...defaultProfile,
      ...profile,
      method: 'segmented',
    };
  }

  // Merge with cookieProfile.
  if (cookieProfile) {
    combinedProfile = {
      ...cookieProfile.profile,
      ...profile,
      open: false,
    };
  }
  return combinedProfile;
};

const setResidencyFromLocation = (
  profile,
  defaultProfile,
  cookieProfile,
  store,
) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      // success callback
      const storeProfile = cookieProfile?.profile || defaultProfile;
      const { latitude } = position.coords;
      const { longitude } = position.coords;

      if (!isWithinAustralia(latitude, longitude)) {
        storeProfile.residency = 'international';
        storeProfile.qualificationCode = 'GIQ-IB';
        storeProfile.locationResponse = 'yes';
      } else {
        storeProfile.locationResponse = 'yes';
      }

      const cobinedProfile = mergedProfile(profile, storeProfile);

      if (Cookies.get('fac-profile')) {
        Cookies.remove('fac-profile');
      }
      Cookies.set('fac-profile', cobinedProfile, {
        expires: 30,
      });

      store.dispatch('updateProfile', cobinedProfile);
    },
    () => {
      // error callback
      const storeProfile = cookieProfile?.profile || defaultProfile;
      storeProfile.locationResponse = 'yes';
      store.dispatch('updateProfile', mergedProfile(profile, storeProfile));
    },
  );
};

export default ({ store, isHMR, app }) => {
  if (isHMR) {
    return;
  }
  // add persisted state as a vue mounted mixin
  if (!app.mixins) {
    app.mixins = [];
  }
  app.mixins.push({
    data() {
      return {
        query: this.$route.query,
      };
    },
    mounted() {
      const cookieProfile = Cookies.getJSON('fac-profile');
      const profile = getParams(this.query);
      const defaultProfile = {
        qualification: 'undergrad',
        residency: 'domestic',
        persona: 'secondary',
        open: false,
        method: 'default',
      };

      if (
        cookiesEmptyOrMatchDefault(defaultProfile, cookieProfile) &&
        !locationPreviouslyRequested(cookieProfile)
      ) {
        setResidencyFromLocation(profile, defaultProfile, cookieProfile, store);
      }

      createPersistedState({
        key: 'fac-profile',
        paths: ['profile'],
        storage: {
          getItem: (key) => Cookies.get(key),
          setItem: (key, value) =>
            Cookies.set(key, value, {
              expires: 30,
            }),
          removeItem: (key) => Cookies.remove(key),
        },
      })(store);

      // If custom params have been set.
      if (Object.keys(profile).length) {
        store.dispatch(
          'updateProfile',
          mergedProfile(profile, defaultProfile, cookieProfile),
        );
      } else if (cookieProfile) {
        if (cookieProfile.profile.open) {
          store.dispatch('updateProfile', cookieProfile.profile);
        }
      } else {
        store.dispatch('updateProfile', defaultProfile);
      }
    },
  });
};
