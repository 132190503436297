import { get } from '@/helpers/get';

export default {
  offering(state) {
    return state.offering;
  },
  isDomesticOnly(state) {
    return get(
      state.offering,
      ['metadata', 'search_display_metadata', 'domestic_only_search_display'],
      false,
    );
  },
  isInternationalOnly(state) {
    return get(
      state.offering,
      [
        'metadata',
        'search_display_metadata',
        'international_only_search_display',
      ],
      false,
    );
  },
  residency(state, getters, rootState, rootGetters) {
    let value = getters.isDomesticOnly ? 'domestic' : undefined;

    if (!value) {
      value = getters.isInternationalOnly ? 'international' : undefined;
    }

    return value || rootGetters.studentType.residency;
  },
};
